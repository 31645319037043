<template>
  <Modal closePosition="hidden">
    <div class="root p-6">
      <h2 class="font-h font-semibold text-2xl text-primary">
        Want to correct the transcript first?
      </h2>

      <div class="mt-2.5 text-sm text-primary">
        We highly recommend that you do!
      </div>

      <div class="mt-4 flex items-center justify-end">
        <Button size="small" variant="secondary" :onClick="close" classname="mr-2.5">
          Export without correcting
        </Button>
        <Button size="small" :onClick="next">
          Correct transcript
        </Button>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Modal from '@/components/base/dialogs/Modal.vue'
import Button from '@/components/base/buttons/Button.vue'

export default {
  components: {
    Modal,
    Button
  },
  computed: {
    ...mapGetters({
      modalProps: 'dialogs/modalProps'
    })
  },
  methods: {
    ...mapActions({
      openModal: 'dialogs/openModal',
      closeModal: 'dialogs/closeModal'
    }),
    close: function() {
      localStorage.setItem('onBoardingEditTranscriptFirstPopover', true)
      if (this.modalProps && typeof this.modalProps.selectOption === 'function') this.modalProps.selectOption({ export: true })
    },
    next: function() {
      if (this.modalProps && typeof this.modalProps.selectOption === 'function') this.modalProps.selectOption({ export: false })
    }
  }
}
</script>
<style lang="less" scoped>
.root {
  width: 560px;
}
</style>
